import axios from "axios";
import { createApi, getAll, getAllPag, createUpdate, deleteItem } from "./baseApi";

const invoicesApi = createApi("invoicing/invoices");

export const getNextInvoiceID = async (payload) => {
	let slug = "invoicing/next-invoice-id";
	if (payload.filter) {
		slug = `${slug}${payload.filter}`;
	}
	const response = await axios.get(`${process.env.REACT_APP_API_URL}${slug}`, {
		headers: { Authorization: payload.token }
	});
	return response.data;
};
export const getInvoiceDetail = async (uuid) => {
	const response = await axios.get(`${process.env.REACT_APP_API_URL}invoicing/invoice/${uuid}`);
	return response.data;
};

export const downloadMultipleInvoices = async (payload) => {
	try {
		// Configuración para obtener el archivo binario (blob)
		const response = await axios.post(
			`${process.env.REACT_APP_API_URL}invoicing/pdf/multiple-download/`,
			payload.data,
			{
				headers: {
					Authorization: payload.token
				},
				responseType: "blob" // Especifica que la respuesta será un archivo binario
			}
		);

		// Crear un enlace para descargar el archivo ZIP
		const url = window.URL.createObjectURL(new Blob([response.data]));
		const a = document.createElement("a");
		a.href = url;
		a.download = "Facturas.zip"; // Nombre del archivo ZIP descargado
		document.body.appendChild(a);
		a.click();
		a.remove(); // Limpiar el enlace del DOM
	} catch (error) {
		console.error("Error al descargar las facturas:", error);
	}
};

export const getInvoiceOptions = async (payload) => {
	const response = await invoicesApi.options("/", {
		headers: { Authorization: payload.token }
	});
	return response.data;
};

export const getAllInvoices = async (payload) => {
	return getAll(invoicesApi, payload);
};
export const getAllInvoicesPag = async (payload) => {
	return getAllPag(invoicesApi, payload);
};
export const createUpdateInvoice = async (payload) => {
	return createUpdate(invoicesApi, payload);
};
export const deleteInvoice = async (payload) => {
	return deleteItem(invoicesApi, payload);
};

const invoiceSettingsApi = createApi("invoicing/settings");

export const getInvoiceSettings = async (payload) => {
	const response = await invoiceSettingsApi.get("", {
		headers: { Authorization: payload.token }
	});
	return response.data;
};

export const updateInvoiceSettings = async (payload) => {
	const response = await invoiceSettingsApi.patch(`/${payload.slug}/`, payload.data, {
		headers: {
			Authorization: payload.token,
			"Content-Type": "multipart/form-data"
		}
	});

	return response.data;
};
