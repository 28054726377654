import { useState, useMemo } from "react";
import { useDisclosure, Text, Flex, IconButton, Tr, Td, Button, useToast } from "@chakra-ui/react";
// comps
import PopoverDelete from "../../../../common/PopoverDelete";
import LandingServiceForm from "./BusinessServiceForm";
import ResponsiveTable from "../../../../common/Tables/ResponsiveTable";
import BusinessServiceSettingsForm from "./BusinessServiceSettingsForm";
// fs
import { parseDuration } from "../../../../common/functions";
// icons
import { AiOutlineEdit } from "react-icons/ai";
import { IoMdSync } from "react-icons/io";
import { LuSparkle } from "react-icons/lu";
// img
import emptyCars from "../../../../../img/data/emptyClients.png";
// api
import { useQueryClient } from "@tanstack/react-query";
import { useAuthHeader } from "react-auth-kit";
import useAuthMutation from "../../../../../myHooks/useAuthMutation";
import useAuthFetch from "../../../../../myHooks/useAuthFetch";
import { syncLandingData, getAllBusinessServicesPag, deleteBusinessService } from "../../../../../api/businessesApi";

export default function BusinessServicesTable() {
	const { isOpen, onOpen, onClose } = useDisclosure();
	const [page, setPage] = useState(1);
	const [service, setService] = useState();
	const toast = useToast();
	const authHeader = useAuthHeader();
	const QueryClient = useQueryClient();

	const { data, isLoading } = useAuthFetch(["businessServices", page], getAllBusinessServicesPag, page);
	const { mutate, isLoading: lm } = useAuthMutation({
		mutationFn: syncLandingData,
		onSuccess: () => {
			toast({ title: "Datos sincronizados!", status: "success" });
			QueryClient.invalidateQueries(["businessServices"]);
			QueryClient.refetchQueries(["businessServices"], { force: true });
		},
		onError: () => {
			toast({
				title: "¡Ups! Ha habido un error",
				status: "error"
			});
		}
	});
	const handleSyncData = () => {
		const payload = { token: authHeader() };
		mutate(payload);
	};
	const tableData = useMemo(
		() =>
			data?.results?.map((service) => (
				<Tr key={service.id}>
					<Td maxW={["300px", "350px"]} overflow='hidden' textOverflow='ellipsis' whiteSpace='nowrap'>
						<Flex align='center' gap='0.2rem'>
							{service.name}
							{service.is_featured && <LuSparkle size='18px' color='#0258FF' />}
						</Flex>
					</Td>
					<Td> {service.category_display || "-- --"} </Td>
					<Td>
						{service.price}
						{service.is_price_from && "*"}
					</Td>
					<Td> {parseDuration(service.duration)} </Td>
					<Td>
						<Flex gap='1em' key={service.id}>
							<IconButton
								icon={<AiOutlineEdit size='20px' color='#0258FF' />}
								bg='transparent'
								onClick={() => {
									setService(service);
									onOpen();
								}}
							/>
							<PopoverDelete
								deleteKey={["deleteService"]}
								deleteFn={deleteBusinessService}
								slug={service?.id}
								refetchKey={["businessServices"]}
							/>
						</Flex>
					</Td>
				</Tr>
			)),
		[data, onOpen]
	);
	const isEmpty = data?.count < 1;
	return (
		<>
			<Flex w='100%' justify='space-between' gap='2em' mb='1rem'>
				<Text>
					Añade servicios desde el panel de datos, sincronízalos aquí y personaliza su apariencia para la
					página de reservas.
				</Text>
				<Button
					variant='white'
					px='2rem'
					onClick={handleSyncData}
					leftIcon={<IoMdSync size='20px' color='#0258FF' />}
					isLoading={lm}
				>
					Sincronizar
				</Button>
			</Flex>
			<BusinessServiceSettingsForm />
			<ResponsiveTable
				withoutLoading
				withoutButton
				pr='0.5em'
				maxH='95%'
				overflowY='scroll'
				paginated
				data={data}
				tableData={tableData}
				isLoading={isLoading || lm}
				isEmpty={isEmpty}
				onOpen={() => {
					setService();
					onOpen();
				}}
				onClose={onClose}
				page={page}
				setPage={setPage}
				item={{
					label: "Servicio",
					headers: [
						{ key: "name", label: "Nombre", roundedLeft: "lg" },
						{ key: "category_display", label: "Categoría" },
						{ key: "price", label: "Precio(€)" },
						{ key: "duration", label: "Duración" }
					],
					formItem: service,
					isOpen
				}}
				FormComponent={LandingServiceForm}
				emptyImage={emptyCars}
			/>
		</>
	);
}
