import { useEffect, useState } from "react";
import { Flex, Text, Switch, useToast, Button, Image } from "@chakra-ui/react";
// comps
import LoadingSpinner from "../../common/LoadingSpinner";
import BusinessSignatureModal from "./BusinessSignatureModal";
// forms validation
import * as Yup from "yup";
import { Formik } from "formik";
import InputField from "../../common/forms/InputField";
// auth
import { useAuthHeader } from "react-auth-kit";
// api
import useAuthFetch from "../../../myHooks/useAuthFetch";
import useAuthMutation from "../../../myHooks/useAuthMutation";
import { useQueryClient } from "@tanstack/react-query";
import { getInvoiceSettings, updateInvoiceSettings } from "../../../api/invoicesApi";

export default function InvoiceSettingsForm() {
	const authHeader = useAuthHeader();
	const toast = useToast();
	const QueryClient = useQueryClient();
	const [invoiceSettings, setInvoiceSettings] = useState();
	const [signatureData, setSignatureData] = useState(null);

	const { data } = useAuthFetch(["invoiceSettings"], getInvoiceSettings);
	useEffect(() => {
		if (data && data?.length > 0) {
			setInvoiceSettings(data[0]);
			setSignatureData(data[0]?.signature);
		}
	}, [data]);
	const { mutate, isLoading, error } = useAuthMutation({
		mutationFn: updateInvoiceSettings,
		onSuccess: () => {
			toast({ title: "Ajustes actualizados!", status: "success" });
			QueryClient.invalidateQueries(["invoiceSettings"]);
			QueryClient.refetchQueries("invoiceSettings", { force: true });
		},
		onError: () => {
			toast({
				title: "¡Ups! Ha habido un error",
				status: "error"
			});
		}
	});
	const initialValues = {
		name: invoiceSettings?.name || "",
		CIF: invoiceSettings?.CIF || "",
		address: invoiceSettings?.address || "",
		invoice_id_letters: invoiceSettings?.invoice_id_letters || "",
		IBAN: invoiceSettings?.IBAN || "",
		invoice_expiration: invoiceSettings?.invoice_expiration || "",
		display_image: invoiceSettings?.display_image || true,
		display_vehicle_details: invoiceSettings?.display_vehicle_details || true,
		signature: invoiceSettings?.signature || ""
	};
	const validationSchema = Yup.object({
		name: Yup.string(),
		address: Yup.string(),
		CIF: Yup.string(),
		IBAN: Yup.string(),
		invoice_id_letters: Yup.string().required("Es obligatorio"),
		invoice_expiration: Yup.number("Debe ser un número")
	});

	const submit = (values) => {
		//return value && value.type.startsWith("image/");
		if (typeof values?.signature === "string") {
			values.signature = null;
		}
		const payload = {
			data: values,
			slug: invoiceSettings.id,
			token: authHeader()
		};
		mutate(payload);
	};

	if (invoiceSettings) {
		return (
			<Formik
				initialValues={initialValues}
				validationSchema={validationSchema}
				onSubmit={(values) => submit(values)}
				enableReinitialize
			>
				{(formik) => (
					<Flex direction='column' w='100%' gap='2em' mt='1em'>
						<Flex w='100%' gap='2em'>
							<Flex direction='column' gap='1em'>
								<InputField
									label='Nombre legal'
									name='name'
									w='200px'
									isLoading={isLoading}
									error={error?.response?.data?.name}
								/>
								<InputField
									label='CIF'
									name='CIF'
									w='200px'
									isLoading={isLoading}
									error={error?.response?.data?.CIF}
								/>
								<InputField
									isLoading={isLoading}
									label='Dirección de facturación'
									name='address'
									w='325px'
									error={error?.response?.data?.address}
								/>
								<InputField
									isLoading={isLoading}
									label='IBAN'
									hint='Dirección de cuenta para que los clientes ingresen el pago'
									name='IBAN'
									w='325px'
									error={error?.response?.data?.IBAN}
								/>
							</Flex>
							<Flex direction='column' gap='1em'>
								<InputField
									isLoading={isLoading}
									label='ID Factura'
									name='invoice_id_letters'
									w='200px'
									error={error?.response?.data?.invoice_id_letters}
									hint={`Así tu primera factura será ${formik.values.invoice_id_letters}0001`}
								/>
								<InputField
									isLoading={isLoading}
									label='Fecha de expiración (días)'
									name='invoice_expiration'
									w='200px'
									error={error?.response?.data?.invoice_expiration}
									hint='Días de plazo para que el cliente pague la factura'
								/>
								<Text color='black'>Mostrar imágen de marca en las facturas</Text>
								<Switch
									isChecked={formik.values.display_image}
									onChange={() => formik.setFieldValue("display_image", !formik.values.display_image)}
								/>
								<Text color='black'>Mostrar datos de vehículo en las facturas</Text>
								<Switch
									isChecked={formik.values.display_vehicle_details}
									onChange={() =>
										formik.setFieldValue(
											"display_vehicle_details",
											!formik.values.display_vehicle_details
										)
									}
								/>
							</Flex>
						</Flex>
						<Flex direction='column'>
							<Text fontWeight='bold'>Firma negocio</Text>
							{signatureData ? (
								<Image src={signatureData} maxW='250px' />
							) : (
								<BusinessSignatureModal formik={formik} setSignatureData={setSignatureData} />
							)}
						</Flex>
						{formik.errors?.signature && <Text color='red'>{formik.errors.signature}</Text>}
						<Button
							maxW='150px'
							mt='50px'
							alignSelf='end'
							variant='primary'
							isLoading={isLoading}
							isDisabled={!formik.dirty}
							onClick={formik.handleSubmit}
						>
							Guardar
						</Button>
					</Flex>
				)}
			</Formik>
		);
	}
}
