import { Routes, Route } from "react-router-dom";
import { useState, useMemo } from "react";
import { APIProvider } from "@vis.gl/react-google-maps";
// layout
import AppLayout from "./layouts/AppLayout";
import HubLayout from "./layouts/HubLayout.jsx";
// pages
import NotFound from "./pages/NotFound.jsx";
import DetailingHub from "./pages/Pro/DetailingHub.tsx";
import Login from "./pages/Auth/Login.jsx";
import Register from "./pages/Auth/Register.jsx";
import RegisterBeta from "./pages/Auth/RegisterBeta.jsx";
import Activate from "./pages/Auth/Activate.jsx";
import ResetPassword from "./pages/Auth/ResetPassword.jsx";
import ResetPasswordChange from "./pages/Auth/ResetPasswordChange.jsx";
// logged
import Dashboard from "./pages/Free/Dashboard.jsx";
import Data from "./pages/Free/Data.jsx";
import Invoices from "./pages/Free/Invoices.jsx";
import InvoicePDFPage from "./components/pages/invoicing/pdf/InvoicePDFPage.jsx";
import QuotePDFPage from "./components/pages/invoicing/QuotePDFPage.jsx";
import Orders from "./pages/Free/Orders.jsx";
import Settings from "./pages/Free/Settings.jsx";
import PlanExpired from "./pages/Payments/PlanExpired.jsx";
// Payments
import PlanSelection from "./pages/Payments/PlanSelection.tsx";
import PaymentStatus from "./pages/Payments/PaymentStatus.jsx";
import CheckoutStatus from "./pages/Payments/CheckoutStatus.jsx";
// admin
import UserManagement from "./pages/Admin/UserManagement";
import UserActions from "./pages/Admin/UserActions";
import StripeData from "./pages/Admin/StripeData";
import BusinessesMapView from "./pages/Admin/BusinessesMapView.jsx";
// Pro
import Inspection from "./pages/Pro/Inspection.jsx";
import Stock from "./pages/Pro/Stock.jsx";
import Analytics from "./pages/Pro/Analytics.jsx";
import Communication from "./pages/Pro/Communication.jsx";
import BusinessSetings from "./pages/Pro/BusinessSetings.jsx";
import BusinessProfile from "./pages/Pro/BusinessProfile.tsx";
import DepositReceipt from "./pages/Pro/DepositReceipt.tsx";
// auth
import { RequireAuth } from "react-auth-kit";
import { PlanContext } from "./components/common/context/PlanContext";
// oauth
import { GoogleOAuthProvider } from "@react-oauth/google";
// stripe
import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import { SpeedInsights } from "@vercel/speed-insights/react";

export default function App() {
	const [limits, setLimits] = useState();
	const value = useMemo(() => ({ limits, setLimits }), [limits, setLimits]);
	const googleClientId = process.env.REACT_APP_GOOGLE_CLIENT_ID;
	const googleMapsApi = process.env.REACT_APP_GOOGLE_MAPS_API_KEY;
	const stripePromise = loadStripe(`${process.env.REACT_APP_STRIPE_PUBLIC_KEY}`);

	return (
		<GoogleOAuthProvider clientId={googleClientId}>
			<APIProvider apiKey={googleMapsApi}>
				<Elements stripe={stripePromise}>
					<SpeedInsights />
					<PlanContext.Provider value={value}>
						<Routes>
							<Route path='*' element={<NotFound />} />
							{/* 
							<Route
								index
								path='/'
								element={
									<HubLayout>
										<DetailingHub />
									</HubLayout>
								}
							/>
							*/}
							<Route path='/' element={<Login />} />

							<Route index path='/login' element={<Login />} />
							<Route path='/register' element={<Register />} />
							<Route path='/register/beta' element={<RegisterBeta />} />
							<Route path='/activate/:user_id/:token' element={<Activate />} />
							<Route path='/reset' element={<ResetPassword />} />
							<Route path='/reset/:user_id/:token' element={<ResetPasswordChange />} />

							{/* Protected */}
							<Route
								path={"/dashboard"}
								element={
									<RequireAuth loginPath={"/login"}>
										<AppLayout>
											<Dashboard />
										</AppLayout>
									</RequireAuth>
								}
							/>
							<Route
								path={"/ordenes"}
								element={
									<RequireAuth loginPath={"/login"}>
										<AppLayout>
											<Orders />
										</AppLayout>
									</RequireAuth>
								}
							/>
							<Route
								path={"/facturas"}
								element={
									<RequireAuth loginPath={"/login"}>
										<AppLayout>
											<Invoices />
										</AppLayout>
									</RequireAuth>
								}
							/>
							<Route path={"/facturas/:uuid"} element={<InvoicePDFPage />} />
							<Route path={"/presupuestos/:uuid"} element={<QuotePDFPage />} />
							<Route
								path={"/datos"}
								element={
									<RequireAuth loginPath={"/login"}>
										<AppLayout>
											<Data />
										</AppLayout>
									</RequireAuth>
								}
							/>
							<Route
								path={"/stock"}
								element={
									<RequireAuth loginPath={"/login"}>
										<AppLayout>
											<Stock />
										</AppLayout>
									</RequireAuth>
								}
							/>
							<Route
								path={"/negocio"}
								element={
									<RequireAuth loginPath={"/negocio"}>
										<AppLayout>
											<BusinessSetings />
										</AppLayout>
									</RequireAuth>
								}
							/>
							<Route
								path={"/inspecciones"}
								element={
									<RequireAuth loginPath={"/login"}>
										<AppLayout bgMobile='white'>
											<Inspection />
										</AppLayout>
									</RequireAuth>
								}
							/>
							<Route
								path={"/comunicacion"}
								element={
									<RequireAuth loginPath={"/login"}>
										<AppLayout>
											<Communication />
										</AppLayout>
									</RequireAuth>
								}
							/>
							<Route
								path={"/ajustes"}
								element={
									<RequireAuth loginPath={"/login"}>
										<AppLayout>
											<Settings />
										</AppLayout>
									</RequireAuth>
								}
							/>
							<Route
								path={"/reservas/:workshop"}
								element={
									<HubLayout>
										<BusinessProfile />
									</HubLayout>
								}
							/>
							<Route path={"/resguardo-deposito/:receiptId"} element={<DepositReceipt />} />
							<Route
								path={"/estadisticas"}
								element={
									<RequireAuth loginPath={"/login"}>
										<AppLayout>
											<Analytics />
										</AppLayout>
									</RequireAuth>
								}
							/>
							<Route
								path={"/paymentstatus"}
								element={
									<RequireAuth loginPath={"/login"}>
										<AppLayout>
											<PaymentStatus />
										</AppLayout>
									</RequireAuth>
								}
							/>
							<Route
								path={"/planexpired"}
								element={
									<RequireAuth loginPath={"/login"}>
										<AppLayout>
											<PlanExpired />
										</AppLayout>
									</RequireAuth>
								}
							/>
							<Route
								path={"/checkoutstatus"}
								element={
									<RequireAuth loginPath={"/login"}>
										<AppLayout>
											<CheckoutStatus />
										</AppLayout>
									</RequireAuth>
								}
							/>
							<Route
								path={"/planes"}
								element={
									<RequireAuth loginPath={"/login"}>
										<AppLayout>
											<PlanSelection />
										</AppLayout>
									</RequireAuth>
								}
							/>
							{/* Admin */}
							<Route
								path={"/admin/users-management"}
								element={
									<RequireAuth loginPath={"/login"}>
										<AppLayout>
											<UserManagement />
										</AppLayout>
									</RequireAuth>
								}
							/>
							<Route
								path={"/admin/stripe"}
								element={
									<RequireAuth loginPath={"/login"}>
										<AppLayout>
											<StripeData />
										</AppLayout>
									</RequireAuth>
								}
							/>
							<Route
								path={"/admin/businesses-map"}
								element={
									<RequireAuth loginPath={"/login"}>
										<AppLayout>
											<BusinessesMapView />
										</AppLayout>
									</RequireAuth>
								}
							/>
							<Route
								path={"/admin/user-actions"}
								element={
									<RequireAuth loginPath={"/login"}>
										<AppLayout>
											<UserActions />
										</AppLayout>
									</RequireAuth>
								}
							/>
						</Routes>
					</PlanContext.Provider>
				</Elements>
			</APIProvider>
		</GoogleOAuthProvider>
	);
}
