import { useState, useMemo } from "react";
import {
	Switch,
	useDisclosure,
	IconButton,
	Flex,
	Text,
	TableContainer,
	Table,
	Thead,
	Tr,
	Th,
	Tbody,
	Td
} from "@chakra-ui/react";
import moment from "moment/moment";
// comps
import OrderByButton from "../../common/Tables/OrderByButton";
import PopoverDelete from "../../common/PopoverDelete";
import LoadingSpinner from "../../common/LoadingSpinner";
import PaginationMenu from "../../common/Tables/PaginationMenu";
import UserDetail from "./UserDetailCard";
import UserPlanDisplay from "../../plans/UserPlanDisplay";
import SearchInput from "../../common/Tables/SearchInput";
// icons
import { FaWhatsapp } from "react-icons/fa";
import { AiOutlineEye } from "react-icons/ai";
import { FaLocationDot } from "react-icons/fa6";
// api
import useAuthQuery from "../../../myHooks/useAuthQuery";
import { getAllUsers, deleteUser } from "../../../api/adminApi";
// auth
import { useAuthHeader } from "react-auth-kit";

export default function UsersTable() {
	const [user, setUser] = useState("");
	const [ordering, setOrdering] = useState("");
	const [showAdmins, setShowAdmins] = useState(false);
	const [showInactive, setShowInactive] = useState(true);
	const [showOnlyPro, setShowOnlyPro] = useState(false);
	const [searchInput, setSearchInput] = useState("");
	const [searchQuery, setSearchQuery] = useState("");
	const { isOpen, onOpen, onClose } = useDisclosure();
	const [page, setPage] = useState(1);
	const authHeader = useAuthHeader();

	const { data, isLoading } = useAuthQuery({
		queryKey: ["users", page, ordering, showAdmins, showInactive, searchQuery, showOnlyPro],
		queryFn: () =>
			getAllUsers({
				auth: authHeader(),
				page,
				ordering,
				showAdmins,
				showInactive,
				searchQuery,
				showOnlyPro
			})
	});

	const handleButtonFilter = (newOrdering) => {
		setOrdering(newOrdering);
	};
	const tableData = useMemo(
		() =>
			data?.results?.map((user) => (
				<Tr key={user.id}>
					<Td>{user.name || "-- --"}</Td>
					<Td>{user.business.name || "-- --"}</Td>
					<Td>{user.email.split("@")[0]}</Td>
					<Td> {user.tutorial_last_step} </Td>
					<Td> {user.total_orders} </Td>
					<Td> {user.total_invoices} </Td>
					<Td> {moment(user.created_at).format("l")} </Td>
					<Td> {moment(user.last_login).format("l")} </Td>
					<Td>
						<Flex align='center' gap='0.5rem'>
							<UserPlanDisplay plan={user.plan[0]?.plan?.name} />
							{user.plan[0]?.has_expired ? (
								<Text color='red'>Expired</Text>
							) : (
								<Text>{user.plan[0]?.days_to_expire}</Text>
							)}
							{user.extra_plan && user.extra_plan[0] && <FaWhatsapp size='20px' color='green' />}
							{user.plan[0]?.is_annual && <Text>Anual</Text>}
						</Flex>
					</Td>
					<Td>{user.is_active ? <Text color='green.500'>SÍ</Text> : <Text color='red.500'>NO</Text>}</Td>
					<Td>{user.business.address.formatted_address && <FaLocationDot />}</Td>
					<Td>
						<Flex gap='1em' key={user.id} align='center'>
							<IconButton
								icon={<AiOutlineEye size='20px' color='blue' />}
								bg='transparent'
								border='1px solid lightgrey'
								onClick={() => {
									setUser(user);
									onOpen();
								}}
							/>
							<PopoverDelete
								deleteKey={["deleteUser"]}
								deleteFn={deleteUser}
								slug={user?.id}
								refetchKey={["users"]}
							/>
						</Flex>
					</Td>
				</Tr>
			)),
		[data]
	);
	return (
		<Flex direction='column'>
			<Flex w='100%' align='center'>
				<Text mr='0.5em'>Only PROs</Text>
				<Switch
					defaultChecked={false}
					isChecked={showOnlyPro}
					onChange={() => setShowOnlyPro(!showOnlyPro)}
					colorScheme='blue'
				/>
				<Text ml='2em' mr='0.5em'>
					Show Admins
				</Text>
				<Switch
					defaultChecked={false}
					isChecked={showAdmins}
					onChange={() => setShowAdmins(!showAdmins)}
					colorScheme='blue'
				/>
				<Text ml='2em' mr='0.5em'>
					Show Inactive
				</Text>
				<Switch
					defaultChecked={true}
					value={showInactive}
					onChange={() => setShowInactive(!showInactive)}
					colorScheme='blue'
					mr='2em'
				/>
				<SearchInput searchQuery={searchQuery} setSearchQuery={setSearchQuery} />
			</Flex>
			<TableContainer
				mt='1em'
				minH='35vh'
				width={{ base: "100%", md: "650px", lg: "850px", xl: "1400px", "2xl": "1700px" }}
			>
				<Table variant='unstyled' size='sm'>
					<Thead>
						<Tr bg='lightgrey' color='darkblue' py='1.5em'>
							<Th roundedLeft='lg'>Nombre</Th>
							<Th>Negocio</Th>
							<Th>Email</Th>
							<Th>Tut</Th>
							<Th>O.T</Th>
							<Th>In.</Th>
							<Th>Created</Th>
							<Th>
								Login
								<OrderByButton filter='last_login' onToggleButton={handleButtonFilter} />
							</Th>
							<Th>Plan</Th>
							<Th>Activo</Th>
							<Th />
							<Th roundedRight='lg' />
						</Tr>
					</Thead>

					{!isLoading ? (
						<Tbody>{tableData}</Tbody>
					) : (
						<Tbody>
							<Tr>
								<Td colSpan={10} textAlign='center'>
									<LoadingSpinner h='30vh' />
								</Td>
							</Tr>
						</Tbody>
					)}
				</Table>
				<PaginationMenu
					total={data?.count}
					next={data?.next}
					prev={data?.previous}
					page={page}
					setPage={setPage}
				/>
			</TableContainer>
			<UserDetail isOpen={isOpen} onClose={onClose} user={user} />
		</Flex>
	);
}
