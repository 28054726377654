import { useState, useEffect, useCallback } from "react";
import {
	Button,
	useToast,
	Flex,
	Text,
	Input,
	Switch,
	Drawer,
	DrawerHeader,
	DrawerOverlay,
	DrawerContent,
	DrawerCloseButton,
	DrawerBody,
	DrawerFooter
} from "@chakra-ui/react";
import moment from "moment";
// icons
import { PiChatTeardropTextBold } from "react-icons/pi";
import { MdOutlineWebAsset } from "react-icons/md";
//comps
import InfoTooltip from "../../../../common/InfoTooltip";
// forms validation
import * as Yup from "yup";
import { Formik } from "formik";
import InputField from "../../../../common/forms/InputField";
import TextareaField from "../../../../common/forms/TextareaField";
import SelectField from "../../../../common/forms/SelectField";
// auth
import { useAuthHeader } from "react-auth-kit";
// api
import useAuthMutation from "../../../../../myHooks/useAuthMutation";
import useAuthFetch from "../../../../../myHooks/useAuthFetch";
import { useQueryClient } from "@tanstack/react-query";
import { createUpdateBusinessService, getBusinessServiceOptions } from "../../../../../api/businessesApi";

export default function BusinessServiceForm({ onClose, isOpen, item: service }) {
	const toast = useToast();
	const authHeader = useAuthHeader();
	const QueryClient = useQueryClient();
	const [hours, setHours] = useState(0);
	const [mins, setMins] = useState(0);

	const { data } = useAuthFetch(["businessServicesOptions"], getBusinessServiceOptions);
	const serviceCategoryOptions = data?.actions?.POST?.category?.choices;
	const servicePriorityOptions = data?.actions?.POST?.priority?.choices;

	const { isLoading, mutate, error } = useAuthMutation({
		mutationFn: createUpdateBusinessService,
		onSuccess: () => {
			toast({ title: "Servicio creado con exito!", status: "success" });
			QueryClient.invalidateQueries(["businessServices"]);
			QueryClient.refetchQueries("businessServices", { force: true });
			onClose();
		},
		onError: () => {
			toast({
				title: "Ups! Ha habido un error",
				status: "error"
			});
		}
	});
	const initialValues = {
		name: service?.name || "",
		description: service?.description || "",
		what_includes: service?.what_includes || "",
		category: service?.category || "",
		priority: service?.priority || "",
		is_featured: service?.is_featured || false,
		is_price_from: service?.is_price_from || false,
		more_information_url: service?.more_information_url || ""
	};
	const validationSchema = Yup.object({
		name: Yup.string().required("El nombre es obligatorio"),
		priority: Yup.string(),
		category: Yup.string(),
		more_information_url: Yup.string().url("El formato de la URL no es válido")
	});

	const submit = (values) => {
		const payload = service
			? { data: { ...values }, slug: service.id, token: authHeader() }
			: { data: { ...values }, token: authHeader() };
		mutate(payload);
	};

	const parseDuration = useCallback(() => {
		if (service) {
			const formattedMinutes = moment.duration(service.duration, "minutes");
			const hours = formattedMinutes.hours();
			const days = formattedMinutes.days();
			setMins(formattedMinutes.minutes());
			if (days > 0) {
				setHours(hours + days * 24);
			} else {
				setHours(hours);
			}
		}
	}, [service]);

	useEffect(() => {
		parseDuration();
	}, [parseDuration]);

	const closeForm = () => {
		setHours(0);
		setMins(0);
		onClose();
	};
	const getDurationDisplay = () => {
		let duration = "";
		if (hours > 0) duration = `${hours} horas`;
		if (mins > 0) duration = `${duration} ${mins} minutos`;
		return duration;
	};

	return (
		<>
			<Drawer isOpen={isOpen} placement='right' size='md' onClose={closeForm}>
				<DrawerOverlay />
				<DrawerContent>
					<DrawerCloseButton />
					<DrawerHeader>{service ? "Editar" : "Crear"} Servicio</DrawerHeader>
					<Formik
						initialValues={initialValues}
						validationSchema={validationSchema}
						onSubmit={(values) => submit(values)}
					>
						{(formik) => (
							<>
								<DrawerBody>
									<Flex direction='column' gap='1em'>
										<InputField
											label='Nombre'
											name='name'
											required
											placeholder='Ej: Limpieza integral'
											error={error?.response?.data?.name}
										/>
										<SelectField
											label='Categoría'
											name='category'
											placeholder='Elige un categoría'
											choices={serviceCategoryOptions}
										/>
										<SelectField
											label='Prioridad'
											name='priority'
											placeholder='Prioridad en orden'
											choices={servicePriorityOptions}
										/>
										<TextareaField
											label='Descripción'
											icon={<PiChatTeardropTextBold size='20px' color='black' />}
											name='description'
										/>
										<TextareaField
											label='¿Qué Incluye?'
											icon={<PiChatTeardropTextBold size='20px' color='black' />}
											name='what_includes'
										/>
										<InputField
											placeholder='https://'
											name='more_information_url'
											label='URL Más Información'
											icon={<MdOutlineWebAsset size='20px' color='black' />}
										/>
										<Flex gap='0.5rem' align='center'>
											<Text>¿Servicio Destacado?</Text>
											<Switch
												isChecked={formik.values.is_featured}
												name='is_featured'
												onChange={() =>
													formik.setFieldValue("is_featured", !formik.values.is_featured)
												}
											/>
										</Flex>
										{error?.response?.data?.is_featured && formik.values.is_featured && (
											<Text color='red'>{error.response.data.is_featured}</Text>
										)}
										<Flex gap='0.5rem' align='center'>
											<Text>¿Servicio con precio "Desde"?</Text>
											<Switch
												isChecked={formik.values.is_price_from}
												name='is_price_from'
												onChange={() =>
													formik.setFieldValue("is_price_from", !formik.values.is_price_from)
												}
											/>
										</Flex>
										<Flex direction='column' gap='0.5rem'>
											<Text>Precio</Text>
											<Input isDisabled bg='white' value={`${service.price}€`} />
										</Flex>
										<Flex direction='column' gap='0.5rem'>
											<Text>Duración</Text>
											<Input isDisabled bg='white' value={getDurationDisplay()} />
										</Flex>
									</Flex>
								</DrawerBody>
								<DrawerFooter>
									<Flex justify='right' columnGap='3' mt='3'>
										<Button variant='white' onClick={closeForm}>
											Cancelar
										</Button>
										<Button
											variant='primary'
											isDisabled={!formik.dirty}
											onClick={formik.handleSubmit}
											isLoading={isLoading}
										>
											Guardar
										</Button>
									</Flex>
								</DrawerFooter>
							</>
						)}
					</Formik>
				</DrawerContent>
			</Drawer>
		</>
	);
}
