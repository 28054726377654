import { Flex, Text, Highlight } from "@chakra-ui/react";
// comps
import CheckoutButton from "./CheckoutButton";
// icons
import { AiOutlineCheck } from "react-icons/ai";

interface PlanCardProps {
	planName: "PRO" | "LITE" | "WHATSAPP";
	price: number;
	period: "monthly" | "biannual" | "annual";
	features: string[];
}

export default function PlanCard({ planName, price, period, features }: PlanCardProps) {
	return (
		<Flex rounded='lg' py='1.5em' px='2em' bg='white' direction='column' gap='1em' maxW='350px' h='100%'>
			<Flex gap='1rem' align='center'>
				<Text fontSize='22px' fontWeight='bold'>
					<Highlight query={"another"} styles={{ color: "darkblue" }}>
						another
					</Highlight>
					<Highlight query={"tool"} styles={{ color: "blue.500" }}>
						tool
					</Highlight>
					<span> </span>
					{planName}
				</Text>
			</Flex>
			<Text my='0.5em' fontSize='16px'>
				Exprime el potencial de tu negocio. Deja de perder el tiempo en lo que no te gusta y genera más
				ingresos.
			</Text>
			{features?.map((feature, index) => (
				<Flex align='center' key={index}>
					<AiOutlineCheck color={"#0258FF"} size='18px' />
					<Text fontSize='14px' ml='10px' color='darkblue' fontWeight='regular'>
						{feature}
					</Text>
				</Flex>
			))}
			<Flex gap='0.2rem' align='center'>
				<Text color='blue.500' fontWeight='bold' fontSize='32px'>
					{price}€
				</Text>
				<Text fontSize='12px'>/mes</Text>
			</Flex>
			<CheckoutButton label='Actualizar' plan={planName} period={period} />
		</Flex>
	);
}
