import axios from "axios";

const adminApi = axios.create({
	baseURL: `${process.env.REACT_APP_API_URL}admin/`
});

const plansApi = axios.create({
	baseURL: `${process.env.REACT_APP_API_URL}admin/plans/`
});

export const loginAsUser = async (payload) => {
	const response = await adminApi.post("login-as", payload.data, {
		headers: { Authorization: payload.token }
	});
	return response.data;
};
export const renewUserTrialPlan = async (payload) => {
	const response = await adminApi.post("renew-userplan", payload.data, {
		headers: { Authorization: payload.token }
	});
	return response.data;
};

export const manageExtraPlan = async (payload) => {
	const response = await adminApi.post("manage-extraplan", payload.data, {
		headers: { Authorization: payload.token }
	});
	return response.data;
};

export const getAllUsers = async (payload) => {
	let url = "users";
	if (payload.page) {
		url = `${url}/?page=${payload.page}`;
	}
	if (payload.ordering) {
		url = `${url}&ordering=${payload.ordering}`;
	}
	if (payload.showAdmins) {
		url = `${url}&show_admins=${payload.showAdmins}`;
	}
	if (payload.showInactive) {
		url = `${url}&show_inactive=${payload.showInactive}`;
	}
	if (payload.searchQuery) {
		url = `${url}&search=${payload.searchQuery}`;
	}
	if (payload.showOnlyPro) {
		url = `${url}&show_only_pro=${payload.showOnlyPro}`;
	}
	const response = await adminApi.get(url, {
		headers: { Authorization: payload.auth }
	});
	return response.data;
};

export const getLoginRegisterLog = async (payload) => {
	let url = "logs";
	if (payload.page) {
		url = `${url}/?page=${payload.page}`;
	}
	const response = await adminApi.get(url, {
		headers: { Authorization: payload.auth }
	});
	return response.data;
};

export const getStripeData = async (payload) => {
	const response = await adminApi.get("stripe-stats", {
		headers: { Authorization: payload.token }
	});
	return response.data;
};

export const getUserActions = async (payload) => {
	let url = "actions";
	if (payload.page) {
		url = `${url}/?page=${payload.page}`;
	}
	const response = await adminApi.get(url, {
		headers: { Authorization: payload.auth }
	});
	return response.data;
};

export const deleteUserAction = async (payload) => {
	const response = await adminApi.delete(`/actions/${payload.slug}/`, {
		headers: { Authorization: payload.token }
	});
	return response.data;
};
export const bulkDeleteUserAction = async (payload) => {
	const response = await adminApi.delete("/actions/bulk-delete/", {
		headers: { Authorization: payload.token },
		data: payload.data // Asegúrate de enviar los datos aquí
	});
	return response.data;
};

export const updateUser = async (payload) => {
	const response = await adminApi.put(`users/${payload.slug}/`, payload.data, {
		headers: { Authorization: payload.token }
	});
	return response.data;
};

export const deleteUser = async (payload) => {
	const response = await adminApi.delete(`users/${payload.slug}`, {
		headers: { Authorization: payload.token }
	});
	return response.data;
};

export const getAllPlans = async (payload) => {
	const response = await plansApi.get("", {
		headers: { Authorization: payload.token }
	});
	return response.data;
};

export const updateUserPlan = async (payload) => {
	const response = await adminApi.patch(`userplan/${payload.filter}`, payload.data, {
		headers: { Authorization: payload.token }
	});
	return response.data;
};

export const createUpdatePlan = async (payload) => {
	let response = {};
	if (payload.slug) {
		response = await plansApi.put(`/${payload.slug}/`, payload.data, {
			headers: { Authorization: payload.token }
		});
	} else {
		response = await plansApi.post("/", payload.data, {
			headers: { Authorization: payload.token }
		});
	}

	return response.data;
};

export const deletePlan = async (payload) => {
	const response = await plansApi.delete(`${payload.slug}/`, {
		headers: { Authorization: payload.token }
	});
	return response.data;
};
