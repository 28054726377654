import { useEffect, useContext, useState } from "react";
import { Flex, Progress } from "@chakra-ui/react";
import { PlanContext } from "./context/PlanContext";
// comps
import InfoTooltip from "./InfoTooltip";

interface ParsedState {
	whatsapp_p: number;
	whatsapp_color: string;
}

export default function PlanLimitsProgressPro() {
	const { limits } = useContext(PlanContext);
	const [parsed, setParsed] = useState<ParsedState | undefined>(undefined);

	const sent = limits?.whatsapp_used || 0;
	const limit = limits?.whatsapp_limit || 60;
	const available = limits?.whatsapp_enabled || false;
	useEffect(() => {
		// Calcular el porcentaje de WhatsApp usado
		const whatsapp_p = (sent / limit) * 100;

		// Definir el color basado en el porcentaje
		let whatsapp_color = "green";
		if (whatsapp_p > 70 && whatsapp_p < 90) {
			whatsapp_color = "orange";
		} else if (whatsapp_p >= 90) {
			whatsapp_color = "red";
		}

		// Actualizar el estado parsed con el porcentaje y color
		setParsed({
			whatsapp_p,
			whatsapp_color
		});
	}, [sent, limit]);
	if (available) {
		return (
			<Flex direction='column' gap='0.2em'>
				<Progress value={parsed?.whatsapp_p} colorScheme={parsed?.whatsapp_color} h='5px' rounded='md' />
				<InfoTooltip
					label={"Mensajes"}
					desc={`LLevas un ${Math.floor(parsed?.whatsapp_p || 0)} % de Whatsapp enviados (${sent} / ${limit})`}
				/>
			</Flex>
		);
	}
	return <></>;
}
